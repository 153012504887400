@import "./cart.css";
@import "./checkout.css";
@import "./progress-bar.css";
@import "./slide-pane.css";
@import "./check-delivery.css";
@import "./toast.css";
@import "./slider.css";
@import "./countdown.css";
@import "./calendar.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Jeko";
  src: url("/assets/fonts/inter-regular.woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Jeko Medium";
  src: url("/assets/fonts/inter-medium.woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Jeko Semibold";
  src: url("/assets/fonts/inter-semibold.woff2");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Jeko-Bold";
  src: url("/assets/fonts/inter-bold.woff2");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Jeko Extrabold";
  src: url("/assets/fonts/inter-extrabold.woff2");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter-regular.woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter Medium";
  src: url("/assets/fonts/inter-medium.woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter Semibold";
  src: url("/assets/fonts/inter-semibold.woff2");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Inter Bold";
  src: url("/assets/fonts/inter-bold.woff2");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Soehne Breit Light";
  src: url("/assets/fonts/soehne-breit-light.woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Soehne Breit Medium";
  src: url("/assets/fonts/soehne-breit-medium.woff2");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Soehne Breit Bold";
  src: url("/assets/fonts/soehne-breit-bold.woff2");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Soehne Breit Extrabold";
  src: url("/assets/fonts/soehne-breit-extrabold.woff2");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Hyperwave Two';
  src: url('/assets/fonts/HyperwaveTwo.woff2') format('woff2'),
    url('/assets/fonts/HyperwaveTwo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body, p, h1, h2, h3 {
  letter-spacing: -0.2px !important;
}

body {
  @apply text-base font-interMedium font-inter leading-[1.5];
  /* overflow: auto !important;
  padding-right: 0px !important; */
}

h1 {
  @apply text-[34px] md:text-7xl lg:text-[54px] font-interMedium;
}

h2 {
  @apply text-[30px] md:text-3xl lg:text-7xl font-inter;
}

h3 {
  @apply text-lg md:text-2xl lg:text-6xl font-interMedium;
}

.user-account:hover .user-slide {
  transform: scaleY(1);
}

.nav-links .contact-slide {
  transition: 0.2s ease-out 0.5s;
}

.mobile-mega-nav .slide-pane__close {
  @apply opacity-100 border-r-0 !pl-4 !mr-0;
}

.mobile-mega-nav .slide-pane__header {
  @apply flex-none h-14;
}

.nav-links:hover .contact-slide {
  transform: scaleY(1);
  transition: 0s ease-out 0s;
}

.nav-links .nav-links-container {
  transition: transform .1s ease,opacity 0ms linear .1s,visibility 0ms linear .1s;
  transform-origin: top center;
  transform: scaleY(0);
}

.nav-links:hover .nav-links-container {
  transform: scaleY(1);
  transition: transform .25s ease,opacity 0ms,visibility 0ms;
}

/* width */
.custom-scroll::-webkit-scrollbar {
  background: #dedede;
  border-radius: 88px;
  width: 4px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #848484;
  border-radius: 88px;
}

/* Handle  hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 100;
}

.bg-gray16 {
  --bg-opacity: 1 !important;
  background-color: rgba(238, 238, 238) !important;
}

.text-orange3 {
  --text-opacity: 1 !important;
}

.text-orange3 {
  color: #ff9635 !important;
  color: rgba(255, 150, 53, var(--text-opacity)) !important;
}

.no-highlights {
  -webkit-tap-highlight-color: transparent;
}

/* For Safari */
.scrollbar-hide::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

/* invalid pwd field bg icon position fix */
input[type=password].border-error {
  background-position: 85% 45%  !important;
}

.ReactModal__Body--open iframe {
  z-index: 40 !important;
}

.range-pill-container::-webkit-scrollbar {
  display: none;
}

#mb-tile strong {
  /* Custom style for bold text within #mb-tile */
  font-family: Inter Bold;
}

#mb-tile mark {
  /* Custom style for bold text within #mb-tile */
  color: #008A22;
  background: none;
}

html::-webkit-scrollbar {
  width: 13px;
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  background-color: #bc2131;
  background-clip: content-box;
}

.tick-credits {
  display: none;
}

.apple-pay-button {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: order;
  -apple-pay-button-style: black;
}

.range-description ul {
  @apply list-disc
}
.react-switch-handle {
  box-shadow: none !important;
}

#storemapper-form {
  z-index: 19 !important;
}

.prc-buttonBack {
  position: absolute;
  top: 40%;
  left: 0;
  transform: translateY(-40%);
}

.prc-buttonNext {
  position: absolute;
  top: 40%;
  right: 0;
  transform: translateY(-40%);
}

.prc-dotGroup {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 10px;
}

.carousel__dot {
  height: 15px;
  width: 15px;
  margin: 3px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #000;
  opacity: 0.5;
}

.carousel__dot--selected {
  background-color: white;
  opacity: 1;
}