/* Styles added for Cart slide pane */

.slide-pane__header {
  background: #fff !important;
}
.slide-pane__content {
  padding: 0 !important;
}
.slide-pane__close,
.slide-pane__title-wrapper {
  margin-left: 0 !important;
}
.slide-pane__title {
  max-width: 100% !important;
  line-height: unset;
}

.ReactModal__Body--open {
  overflow: hidden !important;
}

.slide-pane__overlay {
  @apply mx-auto;
}