
[data-braintree-id='methods-label'] {
  display: none;
}

[data-braintree-id='choose-a-way-to-pay'] {
  @apply hidden;
}

.braintree-placeholder {
  @apply hidden;
}

[data-braintree-id='toggle'] {
  @apply hidden;
}

[data-braintree-id='cvv-label-descriptor'] {
  display: none;
}

[data-braintree-id='discover-card-icon'],
[data-braintree-id='jcb-card-icon'],
[data-braintree-id='unionpay-card-icon'] {
  display: none !important;
}

.braintree-method .braintree-method__label {
  @apply font-inter;
}

.braintree-option__label {
  @apply font-inter;
}

.braintree-method--active {
  @apply border-2 bg-apricot-lite;
}

.braintree-methods--active:not(.braintree-methods--edit) .braintree-method--active {
  border-color: #FF525C;
}

.braintree-method__icon {
  display: none;
}

.braintree-form__descriptor {
  display: none;
}

.braintree-form__label {
  font-family: "Jeko";
}

.braintree-form__field-error, .braintree-sheet__error-text {
  @apply text-error font-inter !important;
}

.paypal-button-container {
  max-width: 100%;
}

[data-braintree-id='methods-container']  {
  display: none;
}

.hide-scroll::-webkit-scrollbar {
  width: 0;
}

@media screen and (max-width: 1022px) {
  .Toastify__toast-container--bottom-center {
    bottom: 5.5em !important;
  }
}

.braintree-option {
  background: #fff !important;
  border-color: #555;
}

.braintree-option:hover {
  background: #F4F4F4 !important;
}

.paypal-buttons-context-iframe {
  z-index: 10 !important;
}
