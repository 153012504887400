/* Progress Bar Styles */

/* Edit these at random intervals to change the animation. */
@keyframes loader {
	100% {
		width: 100%;
	}
}

/* Bar  */
.progress-bar {
	overflow: hidden;
}

.progress-bar span {
	display: block;
  border-radius: 1.5rem;
}

.bar {
  background: rgba(0,0,0,0.075);
}

.progress {
  animation: loader 5s ease infinite;
  background-color: #dc3a32!important;
  --bg-opacity: 1!important;
  background-color: rgba(220,58,50,var(--bg-opacity))!important;
  color: #fff;
  padding: 2px;
  width: 0;
  border-radius: 60px;
}

/* Vertical Centering ============================== */
.progress-bar {
  max-width: 100%;
  position: absolute;
  transform: translate3d(-50%,-50%,0);
}