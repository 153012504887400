/* Styles added for Check Delivery Component */

.thin-scroll {
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.thin-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}

.thin-scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 100px;
}

.thin-scroll::-webkit-scrollbar-thumb {
  background-color: #DEDEDE;
  border-radius: 100px;
}

.map-container {
  width: 100%;
  height: 100%;
}
