.slide-pane__close {
  border-right: 1px #bebebe solid;
  padding: 0px 8px !important;
  margin-right: 1rem;
}
.slide-pane__close svg {
  width: 24px !important;
}
.slide-pane_from_bottom.content-after-open {
  border-radius: 1rem 1rem 0 0;
}
.upcomingDeliverySlidingPane{
  margin-top: 15vh !important;
}
.slide-pane_from_bottom {
  height: 90dvh !important;;
  margin-top: 10dvh !important;;
}
.rangeChervron{
  margin-top: calc(100vh - 536px) !important;
}
.slide-pane__overlay{
  z-index: 55;
}
.slide-pane__header{
  z-index: 70;
}
@media screen and (max-width: 425px) {
  .deliveryFrequency-Sliding-pane-open{
    transform: translateY(40%) !important;
  }
}

.upsell-slide {
  right: 0;
  transition: right 0.5s ease-in-out;
}

.upsell-slide.slide-out {
  right: 392px;
}

.upsell-container {
  right: -100vw;
  transition: right 0.5s ease-in-out;
}

.upsell-container.slide-in {
  right: 0;
}

.upsell-container.slide-out {
  right: -100vw;
}