.noetix-pages .tick {
    font-size: 1rem;
    white-space: nowrap;
    align-items: center;

    [data-view] {
        max-width: 60px;
        width: 50px;

        @media screen and (max-width: 576px) {
            width: 33px;
        }
    }
    
    .round {
        padding: 1rem 0.8rem 0.2rem;
        border-radius: 16px !important;
        border: 1px solid #373737;

        @media screen and (max-width: 576px) {
            padding: 0.5rem 0.2rem 0.2rem;
            width: 75px;
        }
    }

    &.flip-red {
        .round {
            border: 1px solid #ffffff;
        }

        .line {
            background: #ffffff;
        }
        
        .tick-flip-panel {
            background-color: #8C1A21 !important;
        }
    }

    &.white {
        .round {
            border: 1px solid #ffffff;
        }

        .line {
            background: #ffffff;
        }
    }

    .separator {
        padding: 0 16px;

        @media screen and (max-width: 576px) {
            padding: 0 7px;

            svg {
                width: 4px;
            }
        }
    }

    .tick-flip,
    .tick-text-inline {
        margin: 0 !important;
    }

    .tick-label {
        margin-top: 1em;
        font-size: 1em;
        color: $gray-100 !important;
    }

    .tick-char {
        width: 1em;
    }

    .tick-text-inline {
        display: inline-block;
        text-align: center;
        color: $gray-100 !important;
        letter-spacing: 0;
        font-size: clamp(0.5rem, 4vw, 1rem);
        font-weight: 400;
        padding: 10px 15px;

        @media screen and (max-width: 576px) {
            font-size: clamp(0.6rem, 2vw, 1rem);
            padding: 0;
        }
    }

    .tick-text-inline+.tick-text-inline {
        margin-left: -.325em;
    }

    .tick-group {
        margin: 0 .5em;
        text-align: center;
    }

    .tick-flip-panel {
        background-color: #090909 !important;
        border: none;
    }

    .tick-flip-panel-text-wrapper {
        line-height: 1.45 !important;
        left: -7px !important;

        @media screen and (max-width: 576px) {
            left: 1px !important;
        }
    }

    .tick-flip {
        font-weight: 600;
        font-size: clamp(1.8rem, 4vw, 3.5rem);
        border-radius: 16px !important;
        min-width: 0.5rem !important;

        @media screen and (max-width: 992px) {
            font-size: clamp(1rem, 5vw, 5rem);
        }
    }

    .tick-flip-panel-back, .tick-flip-panel-front {
        width: 100%;
    }
}

