.rdp-root * {
    --rdp-today-color: #FF0035;
    --rdp-accent-color: black;
    --rdp-day-height: 44px;
    --rdp-day-width: 44px;
    --rdp-day_button-height: 42px;
    --rdp-day_button-width: 42px;
    --rdp-selected-border: black;
}

.rdp-month_caption {
    padding:  0px 10px;
}

.rdp-selected {
    color: white !important;
    background-color: #FF0035;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
/*.rdp-button_next, .rdp-button_previous {*/
/*    border: 1px solid rgba(228, 230, 231, 0.60);*/
/*    box-shadow: 0px 1px 5px -1px rgba(17, 12, 34, 0.10);*/
/*}*/

