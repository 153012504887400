/* Styles added for overriding react-responsive-carousel */

.control-dots .dot {
  background: #BEBEBE !important;
  box-shadow: none !important;
  opacity: 1 !important;
}

.dot.selected {
  background: #DB2430 !important;
}
